import { eventStatuses } from '../constants/events';
import { http } from '../lib/api/http';
import { V4 } from '../lib/api/urls';

export const fetchViewed = async (excludeId) => {
  let viewedActions = [];
  if (
    Object.keys(localStorage).length !== 0 &&
    typeof localStorage !== 'undefined'
  ) {
    viewedActions = JSON.parse(localStorage.getItem('viewed-events')) || [];
  }
  const viewedList = await http.get(V4.events, {
    ids: excludeId
      ? viewedActions.filter((item) => item !== excludeId).join(',')
      : viewedActions.join(','),
    limit: 8,
    is_web: true,
    status: [eventStatuses.active, 'archive'],
  });
  return viewedList;
};
