import { useTheme } from '@emotion/react';
import { CompilationCard } from './compilation-card';
import AngleRightSVG from '../../public/img/angle-right.svg';
import { DEVICE_SCREENS_MAX_STRING } from '../../lib/consts';
import { Link } from '../styled/Link';
import { City, Compilation } from '../../models';

function CompilationScroll({
  compilations,
  currentCity,
  scroll,
}: {
  compilations: { results: Compilation[] };
  currentCity: City;
  scroll?: boolean;
}) {
  const theme = useTheme();
  return (
    <div
      // compilations compilations_scroll
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      css={{
        ...theme.mixins.flex(),
        ...(scroll
          ? {
              [DEVICE_SCREENS_MAX_STRING.sm]: {
                flexWrap: 'nowrap',
              },
            }
          : {}),
      }}
    >
      {compilations.results.map((compilation) => (
        <div
          key={`CompilationsItem__${compilation.id}`}
          // compilations__item compilations__item_page_main compilations__item_scroll
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          css={{
            marginBottom: 8,
            ...theme.mixins.col(4),
            [DEVICE_SCREENS_MAX_STRING.sm]: scroll
              ? {
                  flex: '0 0 40% !important',
                  maxWidth: '40% !important',
                  marginBottom: 0,
                }
              : {
                  ...theme.mixins.col(2),
                },
          }}
        >
          <CompilationCard
            key={`CompilationCard__${compilation.id}`}
            item={compilation}
            currentCity={currentCity}
          />
        </div>
      ))}
    </div>
  );
}

export function Compilations({
  title,
  compilations,
  href,
  currentCity,
  scroll,
}: {
  title: string;
  compilations: { results: Compilation[] };
  currentCity: City;
  href: string;
  scroll?: boolean;
}) {
  const theme = useTheme();
  if (compilations.results.length === 0) {
    return null;
  }
  return (
    <div
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      css={{
        ...theme.mixins.row(),
        ...(scroll
          ? {
              '> :nth-child(n)': {
                marginBottom: 8,
                marginTop: 8,
              },
            }
          : {
              marginBottom: '4rem',
              [DEVICE_SCREENS_MAX_STRING.md]: {
                marginBottom: '2rem',
              },
            }),
      }}
    >
      <div
        // page__controls
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        css={{
          ...theme.mixins.col(1),
          minHeight: 0,
        }}
      >
        <div
          // main-block-controls-v2
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 0 8px',
          }}
        >
          <Link
            href={href}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            css={{
              fontFamily: 'Montserrat',
              fontWeight: '700',
              fontSize: '2.43rem',
              display: 'flex',
              marginTop: '8px',
              marginBottom: '8px',
              color: theme.colors.black,
              alignItems: 'baseline',
              textDecoration: 'none',
              '&:hover': {
                color: theme.colors.red,
                textDecoration: 'underline',
                svg: {
                  fill: theme.colors.red,
                },
              },
              [DEVICE_SCREENS_MAX_STRING.sm]: {
                fontSize: '1.8rem',
                svg: {
                  height: '1.1rem',
                },
              },
            }}
          >
            {title}
            <AngleRightSVG
              css={{
                width: 30,
                height: '1.5rem',
                [DEVICE_SCREENS_MAX_STRING.md]: {
                  width: 30,
                  height: 15,
                },
              }}
            />
          </Link>
        </div>
      </div>
      {scroll ? (
        <div
          // scroll-wrapper
          css={{
            width: '100%',
            [DEVICE_SCREENS_MAX_STRING.sm]: {
              width: '100%',
              overflowX: 'scroll',
              whiteSpace: 'nowrap',
              paddingTop: 7,
              '&>*': {
                whiteSpace: 'normal',
              },
              '::-webkit-scrollbar': {
                display: 'none',
              },
              '-ms-overflow-style': 'none',
              scrollbarWidth: 'none',
            },
          }}
        >
          <CompilationScroll
            compilations={compilations}
            currentCity={currentCity}
            scroll={scroll}
          />
        </div>
      ) : (
        <CompilationScroll
          compilations={compilations}
          currentCity={currentCity}
          scroll={scroll}
        />
      )}
    </div>
  );
}
